<template>
  <div ref="updateDevice" @click="locationPanelVisible = false">
    <CSDialog
      :dialogTitle="titile"
      dialogWidth="930px"
      :dialogVisible="addRoomVisible"
      @onClose="onCloseBtn()"
      @onConfirm="edit"
      :is-submitting="isSubmitting"
      dialog-confirm-btn-text="保存"
      @click="locationPanelVisible = false"
    >
      <div slot="dialog-content" class="room-form">
        <div class="field-item">
          <label class="field-item-label">设备类型</label>
          <div class="field-item-content" style="position: relative">
            <CSSelect height="40px" iWidth="36px" style="width: 385px">
              <select style="color: #999999"
                id="typelist"
                v-model="editRecharge.equipmentType"
                @change="selectChan(editRecharge.equipmentType)"
              >
                <option value="" >请选择</option>
                <option
                  :value="item"
                  v-for="item in equipmentList"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </CSSelect>
          </div>
        </div>
        <div class="field-item">
          <label class="field-item-label">设备编号</label>
          <div class="field-item-content">
            <input
              style="width: 385px"
              v-model="editRecharge.number"
              placeholder="请输入"
            />
          </div>
        </div>
        <div class="field-item">
          <label class="field-item-label">设备型号</label>
          <div class="field-item-content">
            <input
              style="width: 385px"
              v-model="editRecharge.model"
              placeholder="请输入"
            />
          </div>
        </div>
        <div class="field-item">
          <label class="field-item-label">厂家品牌</label>
          <div class="field-item-content">
            <input
              style="width: 385px"
              v-model="editRecharge.brand"
              placeholder="限10个字"
              maxlength="10"
            />
          </div>
        </div>
        <div class="field-item">
          <label class="field-item-label">设备所在位置</label>
          <div class="field-item-content">
            <!-- <CSSelect height="40px" iWidth="36px" style="width: 385px">
              <select v-model="editRecharge.position" @change="editdBrn">
                <option value="">请选择</option>
                <option
                  :value="item"
                  v-for="item in positionList"
                  :key="item.id"
                >
                  {{ item.specificLocation }}
                </option>
              </select>
            </CSSelect> -->
            <div class="choose-location">
              <CSSelect height="40px" i-width="45px" style="width: 385px">
                <input
                  type="text"
                  placeholder="请选择"
                  class="readonly-input"
                  readonly
                  @click.stop="locationPanelVisible = !locationPanelVisible"
                  v-model="locationName"
                  :disabled="isEdit"
                  :class="{ unselectable: isEdit }"
                  style="border: none"
                />
              </CSSelect>
              <div
                class="location-panel"
                v-if="locationPanelVisible"
                @click.stop
              >
                <div class="location-search">
                  <input
                    type="text"
                    placeholder="搜索位置"
                    v-model="locationKeyWord"
                  />
                  <button
                    class="btn btn-primary font"
                    @click="getLocationList(locationKeyWord)"
                  >
                    查询
                  </button>
                </div>
                <div class="tip">
                  <svg aria-hidden="true" class="icon">
                    <use xlink:href="#icon-menua-zu92"></use>
                  </svg>
                  如果没有可用的位置，请联系运营部负责人添加。
                </div>
                <div class="location-result" v-if="positionList.length > 0">
                  <p
                    v-for="(item, index) in positionList"
                    :key="index"
                    @click="changeLocation(item)"
                  >
                    {{
                      `${item.buildingName || ""}${
                        item.floor ? item.floor + "层" : ""
                      }${item.specificLocation}`
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSDialog>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import ChooseMeterLocation from "@/components/ChooseMeterLocation";
import {
  addEquipmentUrl,
  envQueryLocationUrl,
  envEditEquipmentUrl,
  querySimilarLocationUrl,
} from "@/requestUrl";
export default {
  created() {
    this.$vc.on("equipment", "edit", (res) => {
      this.titile = "添加环境设备";
      this.addRoomVisible = res.isShow;
      this.upDate = res.uppDate;
      this.editRecharge = {
        //设备类型
        equipmentType: "",
        //设备编号
        number: "",
        //设备型号
        model: "",
        //厂家品牌
        brand: "",
        //设备所在位置
        position: "",
        //其他设备
        other: "",
      };
      if (this.upDate) {
        this.titile = "修改环境设备";
        this.editRecharge.id = this.upDate.eid;
        this.selectChan(this.upDate.type == 1 ? { type: "" } : { type: 1 });
        this.editRecharge.equipmentType =
          this.upDate.type == 1
            ? {
                id: 1,
                name: "室外气象站",
                type: "",
              }
            : {
                id: 2,
                name: "室内环境监测传感器",
                type: 1,
              };
      }
      this.editRecharge.number = this.upDate.no;
      this.editRecharge.model = this.upDate.model;
      this.editRecharge.brand = this.upDate.brandName;
      this.editRecharge.other = this.upDate.otherInfo;
    });
  },
  data() {
    return {
      isSubmitting: false,
      isEdit: false,
      locationList: [], //搜索到的位置列表
      locationKeyWord: "", //搜索位置
      locationName: "", //搜索框内容
      locationPanelVisible: false, //位置信息面板是否显示
      titile: "添加环境设备",
      upDate: "",
      addRoomVisible: false,
      editRecharge: {
        //设备id
        id: "",
        //设备类型
        equipmentType: "",
        //设备编号
        number: "",
        //设备型号
        model: "",
        //厂家品牌
        brand: "",
        //设备所在位置
        position: "",
        //其他设备
        other: "",
      },
      //设备类型
      equipmentList: [
        {
          id: 1,
          name: "室外气象站",
          type: "",
        },
        {
          id: 2,
          name: "室内环境监测传感器",
          type: 1,
        },
      ],
      //设备位置列表
      positionList: [],
    };
  },
  components: {
    CSTabBar,
    CSDialog,
    CSSelect,
    ChooseMeterLocation,
  },
  methods: {
    //关闭弹出框
    onCloseBtn() {
      this.addRoomVisible = false;
      this.locationKeyWord = "";
      this.locationName = "";
    },
    //点击选择对应的位置
    changeLocation(item) {
      this.editRecharge.position = item;
      this.locationName =
        (item.buildingName || "") +
        (item.floor ? item.floor + "层" : "" + item.specificLocation);
      this.locationPanelVisible = false;
    },
    //点击搜索 查询对应位置
    getLocationList(item) {
      this.selectChan(item);
    },
    editdBrn() {
      console.log(this.editRecharge.position);
    },
    //下拉框变化
    selectChan(item) {
      this.editRecharge.position = "";
      this.locationName = "";
      if (item == "") {
        this.positionList = "";
        return;
      }
      this.$fly
        .post(querySimilarLocationUrl, {
          regionCode: this.$vc.getCurrentRegion().code, //区域编码
          type: item.type, //1.非公区2.公区  为空查询全部
          specificLocation: this.locationKeyWord,
          //室内type 传 1   室外为空就行
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.positionList = res.data;
          if (this.upDate) {
            var editValue = this.positionList.filter((item) => {
              return item.id == this.upDate.id;
            })[0];
            this.editRecharge.position = editValue ? editValue : "";
            this.locationName =
              (this.editRecharge.position.buildingName || "") +
              (this.editRecharge.position.floor
                ? this.editRecharge.position.floor + "层"
                : "" + this.editRecharge.position.specificLocation);
            //this.upDate = false;
          }
        });
    },
    //确认编辑
    edit() {
      this.modifyEquipment(this.upDate);
    },
    //编辑设备
    modifyEquipment(isUpadte) {
      var EquipmentUrl = addEquipmentUrl;
      var title = "保存成功";
      var requestData = {
        regionCode: this.$vc.getCurrentRegion().code, //区域编码 //区域code
        type: this.editRecharge.equipmentType.id, //类型 设备类型  1:室外气象站  2:室内环境监测传感器
        no: this.editRecharge.number, //设备编号   必填
        model: this.editRecharge.model, //设备型号   必填
        brandName: this.editRecharge.brand, //厂家品牌   必填
        locationId: this.editRecharge.position.id, //位置id    必填
        buildingId: this.editRecharge.position.buildingId, //楼栋id    选填   type=2必填
        buildingName: this.editRecharge.position.buildingName, //楼栋名称   同上
        floor: this.editRecharge.position.floor, //楼层      同上
        specificLocation: this.editRecharge.position.specificLocation, //具体位置   同上
        other_info: this.editRecharge.other, //第三方信息 必填
      };
      console.log(isUpadte);
      if (isUpadte) {

        requestData = {
          ...requestData,
          id: this.editRecharge.id,
        };
        console.log("修改设备");
        EquipmentUrl = envEditEquipmentUrl;
        title = "设备修改成功";
      }
      this.isSubmitting = true;
      this.$fly
        .post(EquipmentUrl, {
          ...requestData,
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.addRoomVisible = false;
          this.$vc.toast(`${title}`);

          this.$emit("updateDevice", "");
        })
        .finally(() => (this.isSubmitting = false));
    },
  },
};
</script>

<style lang="stylus" scoped>
.room-form {
  padding: 27px 30px;

  .field-item {
    margin-bottom: 22px;
    font-size: 0;

    &-label {
      font-size: 24px;
      text-align: right;
      width: 208px;
      margin-right: 40px;
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      margin-bottom: 0;
    }

    &-content {
      display: inline-block;
      vertical-align: middle;
      font-size: 24px;

      input {
        width: 400px;
        height: 40px;
        text-indent: 5px;
        border-radius: 4px;
        border: 1px solid #979797;

        &::placeholder {
          color: #999;
        }
      }

      select {
        width: 220px;
        text-indent: 5px;
      }
    }
  }

  .tips {
    color: #999;
    font-size: 20px;
  }

  .field-font {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 33px;
    color: #999999;
  }
}

.location-search {
  width: 100%;
  display: flex;
}

.location-search .location-input {
  border-radius: 4px;
  border: 1px solid #999;
  padding: 0 10px;
  vertical-align: middle;
  width: 30px;
  height: 40px;
  box-sizing: border-box;
}

.font {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 30px;
  float: right;
  height: 40px;
  padding: 0;
  width: 60px;
  line-height: 40px;
  box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.3);
}

.choose-location {
  position: relative;
  line-height: 1;
  font-size: 20px;

  .readonly-input {
    width: var(--readonly-input-width, 360px);
    height: 40px;
    border-radius: 4px;
    // border 1px solid #999
    padding: 0 10px;
    border: unset;
    font-size: 24px;
    outline: none;
  }

  .location-panel {
    position: absolute;
    background: #fff;
    z-index: 9;
    top: 50px;
    left: 0;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    width: var(--location-panel-width, 400px);
    padding: 10px;

    .location-search {
      width: 100%;

      input[type='text'] {
        border-radius: 4px;
        border: 1px solid #999;
        padding: 0 10px;
        vertical-align: middle;
        width: 300px;
        height: 40px;
        box-sizing: border-box;
      }

      button {
        vertical-align: middle;
        float: right;
        height: 40px;
        padding: 0;
        width: 60px;
        line-height: 40px;
        box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.3);
      }
    }

    .tip {
      color: #999;
      font-size: 20px;
      margin: 6px 0;
      line-height: 1.4;
    }

    .tips {
      color: #999;
      font-size: 20px;
      margin-top: 10px;
    }

    .location-result {
      max-height: 400px;
      overflow-y: auto;
      padding-bottom: 10px;

      p {
        cursor: pointer;
        margin-bottom: 0;
        height: 44.5px;
        line-height: 44.5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:not(:last-of-type) {
          border-bottom: 1px solid #f0f0f0;
        }
      }
    }
  }
}

.font {
  font-size: 20px;
  line-height: 30px !important;
}

.unselectable {
  background: #F0F0F0;
  color: black;
}
</style>
